<template>
  <div :class="$style.detail">
    <info-card :info="info">
      <template #default="slot">
        <span v-if="slot.info.name === $t('videoMonitor.deviceType')">{{
          exchangeValue(slot.info.content, monitorTypes)
        }}</span>
        <span v-else-if="slot.info.name === $t('safety.deviceStatus')"
          ><span
            :class="slot.info.content ? 'online-color' : 'offline-color'"
            >{{
              slot.info.content
                ? $t('greenConstruction.online')
                : $t('greenConstruction.offline')
            }}</span
          ></span
        >
        <span v-else>{{ slot.info.content }}</span>
      </template>
    </info-card>
    <div :class="$style.contentBox">
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="capture-log">
          <span slot="tab">
            <a-badge
              :count="data.alarmCount"
              :number-style="{
                backgroundColor: 'var(--primary)',
                boxShadow: '0 0 0 1px var(--primary) inset',
                top: '-5px',
              }"
            >
              {{ $t('videoMonitor.snapRecord') }}
            </a-badge>
          </span>
          <capture-log
            v-if="activeKey === 'capture-log'"
            :deviceId="data.pkId"
          />
        </a-tab-pane>
        <a-tab-pane key="screenshot" :tab="$t('videoMonitor.screenshot')">
          <screenshot v-if="activeKey === 'screenshot'" :deviceId="data.pkId" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import CaptureLog from './capture-log.vue';
import Screenshot from './screenshot.vue';
import { getMonitorType } from '@/services/device-manage/video-surveillance';
import InfoCard from '@/views/green-construction/components/info-card.vue';

@Component({
  components: {
    CaptureLog,
    Screenshot,
    InfoCard,
  },
})
export default class SurveillanceDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Function, default: () => {} }) close;
  get info() {
    return [
      {
        name: this.$t('safety.deviceName'),
        content: this.data.deviceName || '--',
      },
      {
        name: this.$t('safety.deviceCode'),
        content: this.data.deviceCode || '--',
      },
      {
        name: this.$t('videoMonitor.deviceType'),
        content: this.data.deviceType || '--',
      },
      {
        name: this.$t('safety.belongProject'),
        content: this.data.projectName || '--',
      },
      {
        name: this.$t('common.remark'),
        content: this.data.remark || '--',
      },
      {
        name: this.$t('safety.deviceStatus'),
        content: this.data.online,
      },
    ];
  }
  mounted() {
    this.getOptions();
  }
  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  monitorTypes = [];
  async getOptions() {
    const { monitorTypes } = await getMonitorType();
    this.monitorTypes = monitorTypes;
  }
  exchangeValue(value, option) {
    if (option.length === 0) {
      return;
    }
    const idx = option.findIndex(v => v.value === value);
    return option[idx].name;
  }
  activeKey = 'capture-log';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
}
</script>

<style lang="less" module>
.detail {
  padding-bottom: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - 75px);
  .text {
    color: var(--font-title);
  }
  .textHidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .contentBox {
    .boxTitle {
      font-size: 14px;
      color: var(--font);
      padding: 0 20px 10px;
    }
  }
}
</style>

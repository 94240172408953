<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item :label="$t('videoMonitor.picture')" prop="images">
      <a-upload
        :file-list="form.images"
        :maxCount="1"
        :before-upload="beforeUpload"
        :custom-request="handleUpload"
        @change="handleImgsChange"
        accept="image/*"
      >
        <a-button :class="$style.uploadBtn">
          {{ $t('videoMonitor.addPicConcent') }}
        </a-button>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('greenConstruction.alarmType')"
      prop="alarmType"
    >
      <a-select
        v-model="form.alarmType"
        :placeholder="$t('videoMonitor.selectAlarmType')"
        allowClear
      >
        <a-select-option
          v-for="(item, idx) in typeList"
          :key="idx"
          :value="item.value"
          @click="handleOptionClick(item)"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <!-- <a-form-model-item label="警报内容" prop="reason">
      <a-input
        placeholder="请输入警报内容"
        v-model="form.reason"
        :maxLength="128"
      />
    </a-form-model-item> -->
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { crossStorage } from '@triascloud/cross-storage';
import { addDeviceAlarm } from '@/services/device-manage/device';
/** @name 文件最大20M */
const maxNumber = 20;
const maxSize = 1024 * 1024 * maxNumber;
@Component()
export default class AddScreenshots extends Vue {
  @Prop({ type: String, required: true }) deviceId;
  @Prop({ type: String, required: true }) deviceGroup;
  @Prop({ type: Array, required: true, default: () => [] }) typeList;
  form = {
    images: [],
    reason: '',
    type: '',
    alarmType: undefined,
  };
  rules = {
    images: [
      {
        required: true,
        message: this.$t('videoMonitor.imgRule'),
      },
    ],
    alarmType: [
      {
        required: true,
        message: this.$t('videoMonitor.alarmTypeRule'),
      },
    ],
  };
  beforeUpload(file) {
    const isLimit = file.size <= maxSize;
    return new Promise((resolve, reject) => {
      if (!isLimit) {
        this.$message.error(this.$t('videoMonitor.ImgUploadRule'));
        reject(file);
      } else {
        resolve(file);
      }
    });
  }
  handleImgsChange({ fileList }) {
    this.form.images = fileList.slice(-1);
  }
  picUrl = '';
  handleUpload(fileObj) {
    const { file } = fileObj;
    // const data = await projectList();
    setTimeout(async () => {
      const ossPath = await this.uploadImg(this.form.images[0]);
      // const picUrl = await this.getPicUrl(ossPath);
      fileObj.onSuccess(ossPath, file);
      this.picUrl = ossPath;
    }, 500);
  }
  tenantId = JSON.parse(crossStorage.getItem('tenant')).pkId;
  async uploadImg(file) {
    const files = file.originFileObj;
    const uploadPath = `${this.tenantId}/digital/video`;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const ossPath = await customService.upload(
      files,
      uploadPath,
      () => {},
      false,
    );
    return ossPath;
  }
  async getPicUrl(ossPath) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const picUrl = await customService.getAuth(ossPath);
    return picUrl;
  }
  handleOptionClick(item) {
    this.form.type = item.typeEnum;
    this.form.reason = item.label;
  }
  async getValue() {
    await this.$refs.form.validate();
    try {
      const data = {
        deviceId: this.deviceId,
        picUrl: this.picUrl,
        reason: this.form.reason,
        subType: this.form.alarmType,
        type: this.form.type,
      };
      await addDeviceAlarm(data, this.deviceGroup);
      this.$message.success(this.$t('common.addSuccess'));
      return true;
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.uploadBtn {
  display: inline-block;
  width: 402px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  border: 1px solid var(--font-active);
  color: var(--font-active);
  border-radius: 4px;
}
.uploadBtn:hover {
  color: #ffffff;
  border-color: var(--font-active);
  background: var(--font-active);
}
</style>

<template>
  <tab-container>
    <div slot="operation" :class="$style.search">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
      />
      <a-button type="primary" @click="showFormModel">{{
        $t('videoMonitor.addScreenshot')
      }}</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="action" slot-scope="text, record">
        <a @click="deleteTips(record)">{{ $t('common.delete') }}</a>
      </template>
    </a-table>
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import FormModel from './add-screenshots.vue';
import AlarmTable from '@/views/green-construction/components/mixins/alarm-table';
import { Preview, createFormModal } from '@triascloud/x-components';
import { PublicSize } from '@/views/green-construction/components/utils';
import Tip from '@/components/tip';
import { delDeviceAlarm } from '@/services/device-manage/device';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    TabContainer,
    FormModel,
  },
})
export default class Screenshot extends mixins(AlarmTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => index + 1,
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('videoMonitor.uploadTime'),
        dataIndex: 'createdTime',
        ellipsis: true,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('videoMonitor.uploadScreenshots'),
        dataIndex: 'picUrl',
        customRender: (text, record) => (
          <x-oss-image
            basePath="/oss/iot/oss"
            ossPath={record.picUrl}
            onClick={() => this.openImg(record.picUrl)}
            class="table-img"
          />
        ),
      },
      {
        align: 'left',
        title: this.$t('videoMonitor.alarmContent'),
        dataIndex: 'reason',
        ellipsis: true,
      },
      {
        title: this.$t('common.operation'),
        width: 100,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  manual = true;
  async openImg(picture) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(picture);
    Preview.createModal(abPath);
  }
  deviceGroup = deviceTypeEnum.VS.deviceGroup;
  async deleteTips(record) {
    const text = this.$t('videoMonitor.deleteText');
    const tips = this.$t('videoMonitor.deleteTips');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('safety.importanceTips'),
          onOk: () => {
            this.deleteRecord(record.pkId);
          }, // 解绑 删除
        },
      );
    } catch {
      return false;
    }
  }
  async deleteRecord(id) {
    try {
      await delDeviceAlarm(id, this.deviceGroup);
      this.$message.success(this.$t('safety.deleteSuccess'));
      if (this.pagination.current > 1) {
        if (this.tableData.length === 1) {
          this.pagination.current--;
        }
      }
      await this.getTableList();
    } catch {
      return false;
    }
  }
  async showFormModel() {
    try {
      const result = await createFormModal(
        () => (
          <FormModel
            typeList={this.typeList}
            deviceGroup={this.deviceGroup}
            deviceId={this.deviceId}
          />
        ),
        {
          width: PublicSize.FormModalSize,
          title: this.$t('videoMonitor.addScreenshot'),
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.search {
  display: flex;
  justify-content: space-between;
}
.tableImg {
  cursor: pointer;
  img {
    object-fit: contain;
    width: 30px;
  }
}
</style>

<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    />
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { Preview } from '@triascloud/x-components';
import { mixins } from 'vue-class-component';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import AlarmTable from '@/views/green-construction/components/mixins/alarm-table';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    TabContainer,
  },
})
export default class CaptureLog extends mixins(AlarmTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('videoMonitor.snappingTime'),
        dataIndex: 'createdTime',
        ellipsis: true,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('greenConstruction.alarmType'),
        dataIndex: 'subType',
        ellipsis: true,
        customRender: text => (text ? this.exchangeValue(text) : ''),
      },
      {
        align: 'left',
        title: this.$t('peopleManagement.snapshots'),
        dataIndex: 'picUrl',
        customRender: (text, record) =>
          record.picUrl ? (
            <x-oss-image
              basePath="/oss/iot/oss"
              ossPath={record.picUrl}
              onClick={() => this.openImg(record.picUrl)}
              class="table-img"
            />
          ) : (
            ''
          ),
      },
    ];
  }
  async openImg(picture) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(picture);
    Preview.createModal(abPath);
  }
  deviceGroup = deviceTypeEnum.VS.deviceGroup;
}
</script>

<style lang="less" module>
.tableImg {
  cursor: pointer;
  img {
    object-fit: contain;
    width: 30px;
  }
}
</style>

<template>
  <div :class="$style.content">
    <div>
      <table-search
        @handle-search-submit="handleSearchSubmit($event)"
        @handle-add-device="showFormModel({}, 'add')"
        :form="form"
        :onlineNum="onlineNum"
        :offlineNum="offlineNum"
      >
        <a-form-model-item :label="$t('videoMonitor.deviceType')">
          <a-select
            v-model="form.deviceType"
            :placeholder="$t('videoMonitor.selectDeviceType')"
            style="width: 200px;"
            allowClear
            @change="() => handleSearchSubmit(form)"
          >
            <a-select-option
              v-for="(item, idx) in monitorTypes"
              :key="idx"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>

            <template slot="notFoundContent">
              <a-empty :image="simpleImage" />
            </template>
          </a-select>
        </a-form-model-item>
      </table-search>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.deviceId"
        :loading="loading"
        :transformCellText="({ text }) => text || '-'"
        @change="handlePagination"
      >
        <template slot="online" slot-scope="record">
          <span v-if="record" class="online-color">{{
            $t('greenConstruction.online')
          }}</span>
          <span v-else class="offline-color">{{
            $t('greenConstruction.offline')
          }}</span>
        </template>
        <!-- <template slot="subscription" slot-scope="text, record">
          <span v-if="record.remark == '1'" class="online-color"
            >已订阅
            <a-tooltip>
              <template slot="title">
                取消订阅
              </template>
              <span @click="handleSubscription(record, '0')">
                <ali-icon
                  type="tc-icon-unsubscribe"
                  :class="[$style.statusIcon]"
              /></span> </a-tooltip
          ></span>
          <span v-else class="offline-color"
            >未订阅
            <a-tooltip>
              <template slot="title">
                订阅
              </template>
              <span @click="handleSubscription(record, '1')">
                <ali-icon
                  type="tc-icon-subscription"
                  :class="[$style.statusIcon]"
                /> </span></a-tooltip
          ></span>
        </template> -->
        <template slot="action" slot-scope="record">
          <button-groups
            @handle-edit="showFormModel(record, 'edit')"
            @handle-play="showVideo(record)"
            @handle-detail="showDetail(record)"
            @handle-delete="deleteTips(record)"
            :num="record.alarmCount"
            :showVideo="true"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Empty as EmptyObj } from 'ant-design-vue';
import { LayoutContent } from '@triascloud/x-blocks';
import { createModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
// import { modifyDevice } from '@/services/device-manage/device';
import FormModel from './surveillance-form.vue';
import DeviceDetail from './detail/index.vue';
import OnlineOffline from '@/views/green-construction/components/online-offline.vue';
import VideoView from '@/components/video-view';
import ButtonGroups from '@/views/green-construction/components/button-groups.vue';
import tableSearch from '@/views/green-construction/components/table-search.vue';
import { deviceTypeEnum } from '@/enum/device';
// import { PublicSize } from '@/views/green-construction/components/utils';
import {
  getMonitorType,
  getLiveAddress,
  getChannelNo,
} from '@/services/device-manage/video-surveillance';
import TableCommon from '@/views/green-construction/components/mixins/table-common';
import AliIcon from '@/components/ali-icon';
import { Debounce } from 'lodash-decorators';

@Component({
  components: {
    LayoutContent,
    DeviceDetail,
    FormModel,
    OnlineOffline,
    VideoView,
    ButtonGroups,
    tableSearch,
    Tip,
    AliIcon,
  },
})
export default class VideoSurveillance extends mixins(TableCommon) {
  simpleImage;
  beforeCreate() {
    this.simpleImage = EmptyObj.PRESENTED_IMAGE_SIMPLE;
  }
  created() {
    this.getOptions();
  }
  form = {
    deviceType: undefined,
    device: '',
    projectId: undefined,
  };
  deviceEnum = {
    DUST: '扬尘',
    TCSF: '塔吊',
  };
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceName'),
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('videoMonitor.deviceType'),
        dataIndex: 'deviceType',
        ellipsis: true,
        customRender: text =>
          text ? this.exchangeValue(text, this.monitorTypes) : '',
      },
      {
        title: this.$t('safety.belongProject'),
        dataIndex: 'projectName',
        ellipsis: true,
      },
      {
        title: this.$t('common.remark'),
        dataIndex: 'remark',
        ellipsis: true,
      },
      {
        title: this.$t('safety.deviceStatus'),
        dataIndex: 'online',
        scopedSlots: { customRender: 'online' },
      },
      {
        title: '联动设备',
        dataIndex: 'relatedDevice',
        ellipsis: true,
        customRender: text =>
          text.length
            ? `${this.deviceEnum[text[0].deviceGroup]}(${text[0].deviceName})`
            : '',
      },
      // {
      //   title: '消息订阅状态',
      //   dataIndex: 'subscription',
      //   scopedSlots: { customRender: 'subscription' },
      // },
      {
        title: this.$t('common.operation'),
        width: 280,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  channelList = [];
  videoForm = {
    deviceId: '',
    channelNo: '',
  };
  @Debounce(500)
  async showVideo(record) {
    this.videoForm.deviceId = record.pkId;
    this.videoForm.channelNo = '1';
    try {
      this.channelList = await getChannelNo(record.pkId);
      const data = await getLiveAddress(this.videoForm);
      this.createVideoModal(data, record);
    } catch {
      return false;
    }
  }
  async changeLink(e) {
    this.videoForm.channelNo = e.target.value;
    const data = await getLiveAddress(this.videoForm);
    this.$refs.child.changeVideoLink(data.url);
  }
  createVideoModal(data, record) {
    createModal(
      () => (
        <div class={this.$style.videoWindow}>
          <div style="width: 860px; height: 550px">
            {this.channelList.length < 2 ? (
              ''
            ) : (
              <select
                class={this.$style.channelSelect}
                onChange={this.changeLink}
              >
                {this.channelList.map(item => (
                  <option value={item.channelNo}>{item.channelName}</option>
                ))}
              </select>
            )}
            <video-view ref="child" url={data.url} accessToken={data.token} />
          </div>
          <h1 style="margin-bottom: 0;">{record.deviceName}</h1>
          <span>
            {this.$t('videoMonitor.lastOnlineTime')}：
            {this.dayjs(record.lastDataTime).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>
      ),
      {
        title: this.$t('safety.monitor'),
        width: 900,
        closable: true,
        maskClosable: false,
      },
    );
  }
  monitorTypes = [];
  async getOptions() {
    const { monitorTypes } = await getMonitorType();
    this.monitorTypes = monitorTypes;
  }
  exchangeValue(value, option) {
    const idx = option.findIndex(v => v.value === value);
    return option[idx].name;
  }
  // async handleSubscription(record, status) {
  //   const text = status === '0' ? '确认取消订阅该设备？' : '确认订阅该设备？';
  //   const tips =
  //     status === '0'
  //       ? '取消订阅后，将不能再接收到该设备的警报消息'
  //       : '订阅后，将接收到该设备的警报消息';
  //   await createFormModal(
  //     () => (
  //       <Tip iconStyle={{ padding: '0 0 22px' }}>
  //         <template slot="txt">
  //           <span style="color: #F72F2F">{text}</span>
  //         </template>
  //         <span slot="subTxt">{tips}</span>
  //       </Tip>
  //     ),
  //     {
  //       width: PublicSize.DeleteModalSize,
  //       title: '重要提示',
  //       onOk: () => {
  //         this.changeStatus(record, status);
  //       },
  //     },
  //   );
  // }
  // async changeStatus(record, status) {
  //   try {
  //     const str = status === '1' ? '订阅成功' : '取消订阅成功';
  //     const postData = {
  //       deviceId: record.pkId,
  //       deviceName: record.deviceName,
  //       deviceCode: record.deviceCode,
  //       deviceType: record.deviceType,
  //       idxProjectId: record.idxProjectId,
  //       remark: status,
  //     };
  //     await modifyDevice(postData, this.deviceGroup);
  //     this.$message.success(str);
  //     this.getTableList();
  //   } catch (e) {
  //     this.$message.error('修改失败!');
  //   }
  // }
  deviceGroup = deviceTypeEnum.VS.deviceGroup;
}
</script>

<style lang="less" module>
.content {
  padding: 20px;
  .search {
    display: flex;
    justify-content: space-between;
    .item {
      display: inline-block;
    }
    .btn {
      > button {
        margin: 0 0 10px 10px;
      }
    }
    :global .ant-form-item {
      margin-bottom: 15px;
      .ant-form-item-label > label:after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
  }
  .statusIcon {
    font-size: 18px;
    margin-left: 2px;
    cursor: pointer;
    color: rgb(161, 161, 161);
    transform: translateY(2px);
  }
}
.videoWindow {
  position: relative;
  .channelSelect {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
    outline: none;
    // color: #fff;
    // background-color: rgba(255, 255, 255, 0.3);
    // border: none;
    padding-left: 10px;
    max-width: 300px;
    option {
      color: #000;
    }
  }
}
:global(.DeviceDetail .ant-drawer-body) {
  padding: 0 !important;
}
:global {
  .DeviceDetail {
    .ant-drawer-header {
      .ant-drawer-close {
        left: 0;
      }
    }
    .feedBackBtn {
      position: fixed;
      top: 8px;
      right: 30px;
      z-index: 999;
      color: #ddd;
      font-size: 16px;
      cursor: pointer;
      .anticon {
        margin-right: 8px;
      }
    }
  }
}
</style>
